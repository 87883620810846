import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { PagedDataResponse$1, ReservationResponse, OrderStatusChangedResponse_$reflection, ApiMessageResponse_$reflection, PagedDataResponse$1_$reflection, ReservationResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { Deferred_map, Cmd_resetForm, AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { string_type, union_type, record_type, unit_type, class_type, option_type, int32_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { Default_pagedData, DataSourceItem_$reflection } from "../Models.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { withUrl, signalr as signalr_1, configureLogging, build, register } from "../SignalR.fs.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { tryFind, ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { orderStatuses } from "../API/DataSourceAPI.fs.js";
import { Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { createObj, int32ToString, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { downloadTemplate, search as search_1 } from "../API/ReservationAPI.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { upload as upload_1 } from "../API/OrderAPI.fs.js";
import { FileType } from "../../FootPrint.Portal.DTOs/CustomTypes.fs.js";
import { map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { empty, map as map_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { formatStatusDescriptionAsClass, formatOrderStatusAsClass } from "../Domain/Formatter.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.7.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(PagedDataDeferred, Page, SelectedOrderStatus, UploadFile, DownloadFileDeferred, UploadFileDeferred, SignalRHub) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.Page = (Page | 0);
        this.SelectedOrderStatus = SelectedOrderStatus;
        this.UploadFile = UploadFile;
        this.DownloadFileDeferred = DownloadFileDeferred;
        this.UploadFileDeferred = UploadFileDeferred;
        this.SignalRHub = SignalRHub;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.ReservationUpload.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(ReservationResponse_$reflection()))], ["Page", int32_type], ["SelectedOrderStatus", option_type(DataSourceItem_$reflection())], ["UploadFile", option_type(class_type("Browser.Types.File", void 0))], ["DownloadFileDeferred", Deferred$1_$reflection(unit_type)], ["UploadFileDeferred", Deferred$1_$reflection(unit_type)], ["SignalRHub", class_type("SignalR.IHubConnection")]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SearchAsync", "DownloadFileAsync", "UploadFileAsync", "SetNextPage", "SetPreviousPage", "SetOrderStatus", "SetUploadFile", "OrderStatusChanged"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.ReservationUpload.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(ReservationResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(ReservationResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [], [], [["orderStatus", string_type]], [["Item", class_type("Browser.Types.File", void 0)]], [["Item", OrderStatusChangedResponse_$reflection()]]]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

export function init() {
    let signalR;
    const patternInput = register("OrderStatusChanged", (Item) => (new Msg(7, [Item])), build(configureLogging(1, (signalR = (new signalr_1.HubConnectionBuilder()), withUrl(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api`, signalR)))));
    return [new State(new Deferred$1(0, []), 1, void 0, void 0, new Deferred$1(0, []), new Deferred$1(0, []), patternInput[0]), Cmd_batch(ofArray([patternInput[1], singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    })]))];
}

export function update(msg, state) {
    let input_5, input_7, input_11;
    switch (msg.tag) {
        case 3:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.Page + 1, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch) => {
                    dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 4:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.Page - 1, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 6:
            return [new State(state.PagedDataDeferred, state.Page, state.SelectedOrderStatus, msg.fields[0], state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch_2) => {
                dispatch_2(new Msg(2, [new AsyncMsg$1(0, [])]));
            })];
        case 5:
            return [new State(state.PagedDataDeferred, 1, tryFind((x_1) => (x_1.Id === msg.fields[0]), orderStatuses), state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch_3) => {
                dispatch_3(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_3 = msg.fields[0].fields[0];
                    command_1 = ((input_3.tag === 1) ? Alert_error(Alert_title("Search Reservations", Alert_texts(ofArray(input_3.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_5 = msg.fields[0].fields[0], (input_5.tag === 1) ? Default_pagedData() : input_5.fields[0])]), state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), command_1];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        let orderStatus;
                        const input_1 = state.SelectedOrderStatus;
                        orderStatus = ((input_1 == null) ? void 0 : input_1.DisplayValue);
                        return [new State(new Deferred$1(1, []), state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((x_3) => {
                            Cmd_OfAsync_start(x_3);
                        }, () => search_1(orderStatus, state.Page), void 0, (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, state.Page, state.SelectedOrderStatus, state.UploadFile, new Deferred$1(2, [void 0]), state.UploadFileDeferred, state.SignalRHub), (input_7 = msg.fields[0].fields[0], (input_7.tag === 1) ? Alert_error(Alert_title("Download Template", Alert_texts(ofArray(input_7.fields[0].Messages)))) : Cmd_none())];
                default:
                    if (equals(state.DownloadFileDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.Page, state.SelectedOrderStatus, state.UploadFile, new Deferred$1(1, []), state.UploadFileDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((x_7) => {
                            Cmd_OfAsync_start(x_7);
                        }, downloadTemplate, void 0, (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
            }
        case 2:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    const uploaded = !(msg.fields[0].fields[0].tag === 1);
                    return [new State(state.PagedDataDeferred, uploaded ? 1 : state.Page, uploaded ? void 0 : state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, new Deferred$1(2, [void 0]), state.SignalRHub), Cmd_batch(ofArray([(input_11 = msg.fields[0].fields[0], (input_11.tag === 1) ? Alert_error(Alert_title("Upload Reservations", Alert_texts(ofArray(input_11.fields[0].Messages)))) : singleton((dispatch_4) => {
                        dispatch_4(new Msg(0, [new AsyncMsg$1(0, [])]));
                    })), Cmd_resetForm("upload-form")]))];
                }
                default:
                    if (equals(state.UploadFileDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        const matchValue = state.UploadFile;
                        if (matchValue != null) {
                            const file_1 = matchValue;
                            return [new State(state.PagedDataDeferred, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, new Deferred$1(1, []), state.SignalRHub), Cmd_OfAsyncWith_perform((computation) => {
                                startImmediate(computation);
                            }, () => upload_1(new FileType(2, []), file_1), void 0, (arg_4) => (new Msg(2, [new AsyncMsg$1(2, [arg_4])])))];
                        }
                        else {
                            return [state, Cmd_none()];
                        }
                    }
            }
        default:
            if (msg.fields[0].OrderType === "Reservation") {
                return [new State(Deferred_map((pagedData) => (new PagedDataResponse$1(pagedData.Page, pagedData.PageSize, pagedData.TotalCount, pagedData.NumberOfPages, map((item) => {
                    if (int32ToString(item.Id) === msg.fields[0].OrderId) {
                        return new ReservationResponse(item.Id, item.SourceFileName, item.VmiHub, item.ToOdmCode, item.FromOdmCode, item.AwsPartNumber, item.SupplierSku, item.SupplierCode, item.ExpectedQty, item.UnitPrice, item.EmailAddress, item.Reference1, item.Reference2, item.Reference3, item.CreatedBy, item.CreatedOn, item.UploadedOnFormatted, msg.fields[0].OrderStatus, msg.fields[0].OrderStatusDescription);
                    }
                    else {
                        return item;
                    }
                }, pagedData.Data))), state.PagedDataDeferred), state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
    }
}

export function render(state, dispatch) {
    let elems_30, elems_20, elems_1, elems, matchValue, pagedData, elems_19, elems_4, elems_3, value_24, input_1, elems_2, elems_10, elems_9, elems_6, elems_5, elems_8, elems_7, elems_13, elems_12, elems_11, elems_18, elems_17, elems_16, elems_15, elems_14, input_3, x_1, elems_29;
    return createElement("div", createObj(ofArray([["className", join(" ", ["container", "is-fluid"])], (elems_30 = [createElement("form", createObj(ofArray([["id", "upload-form"], ["className", "box"], (elems_20 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link"]),
        children: "Reservations",
    }), createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-self-center"])], (elems = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: (matchValue = state.PagedDataDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((pagedData = matchValue.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_19 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-fullwidth"])], (elems_3 = [createElement("select", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["disabled", equals(state.PagedDataDeferred, new Deferred$1(1, []))], (value_24 = defaultArg((input_1 = state.SelectedOrderStatus, (input_1 == null) ? void 0 : input_1.Id), ""), ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_24)) {
            e.value = value_24;
        }
    }]), ["onChange", (ev) => {
        dispatch(new Msg(5, [ev.target.value]));
    }], (elems_2 = toList(delay(() => append(singleton_1(createElement("option", {
        children: "-- Order Status --",
        value: "",
        selected: true,
    })), delay(() => map_1((item) => createElement("option", {
        children: item.DisplayValue,
        value: item.Id,
    }), orderStatuses))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["buttons", "is-right"])], (elems_9 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : equals(state.PagedDataDeferred, new Deferred$1(1, []))], (elems_6 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_5 = [createElement("span", {
        children: ["navigate_before"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("span", {
        className: "is-sr-only",
        children: "Previous",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]), ["onClick", (_arg) => {
        dispatch(new Msg(4, []));
    }]]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : equals(state.PagedDataDeferred, new Deferred$1(1, []))], (elems_8 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_7 = [createElement("span", {
        children: ["navigate_next"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("span", {
        className: "is-sr-only",
        children: "Next",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))]), ["onClick", (_arg_1) => {
        dispatch(new Msg(3, []));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_13 = [createElement("button", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals(state.DownloadFileDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty()))))))))))], ["type", "button"], ["disabled", equals(state.DownloadFileDeferred, new Deferred$1(1, []))], (elems_12 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "mr-3"])], (elems_11 = [createElement("i", {
        children: ["file_download"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("span", {
        children: [equals(state.DownloadFileDeferred, new Deferred$1(1, [])) ? "Downloading..." : "Download Template"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))]), ["onClick", (_arg_2) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_18 = [createElement("div", createObj(ofArray([["className", join(" ", ["file", "has-name", "is-small", "is-fullwidth"])], (elems_17 = [createElement("label", createObj(ofArray([["className", "file-label"], (elems_16 = [createElement("input", {
        className: join(" ", toList(delay(() => append(singleton_1("file-input"), delay(() => (equals(state.UploadFileDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))),
        type: "file",
        name: "OrderFileUpload",
        disabled: equals(state.UploadFileDeferred, new Deferred$1(1, [])),
        onChange: (ev_1) => {
            const files = ev_1.target.files;
            if (!(files == null) && (files.length > 0)) {
                dispatch(new Msg(6, [files.item(0)]));
            }
        },
    }), createElement("span", createObj(ofArray([["className", "file-cta"], (elems_15 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "mr-3"])], (elems_14 = [createElement("i", {
        children: ["file_upload"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("span", {
        className: "file-label",
        children: equals(state.UploadFileDeferred, new Deferred$1(1, [])) ? "Uploading..." : "Upload",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("span", {
        className: "file-name",
        children: defaultArg((input_3 = state.UploadFile, (input_3 == null) ? void 0 : ((x_1 = input_3, x_1.name))), ""),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_29 = toList(delay(() => {
        let elems_28, elems_27, elems_22, elems_21, elems_26;
        const matchValue_1 = state.PagedDataDeferred;
        switch (matchValue_1.tag) {
            case 1:
                return singleton_1(render_1());
            case 2: {
                const pagedData_1 = matchValue_1.fields[0];
                if (pagedData_1.TotalCount === 0) {
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "Nothing to see here...",
                    }));
                }
                else {
                    const thClasses = ofArray(["px-3", "py-2", "has-text-link"]);
                    const tdClasses = ofArray(["px-3", "py-2"]);
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_28 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_27 = [createElement("thead", createObj(singleton((elems_22 = [createElement("tr", createObj(singleton((elems_21 = [createElement("th", {
                        className: join(" ", thClasses),
                        children: "VMI Hub",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "To ODM Code",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "From ODM Code",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "AWS Part #",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Supplier SKU",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Supplier Code",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Expected Qty",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Unit Price",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Email Address",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Reference 1",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Reference 2",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Reference 3",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Uploaded By",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Uploaded On",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Order Status",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Order Status Description",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])))), createElement("tbody", createObj(singleton((elems_26 = toList(delay(() => map_1((item_1) => {
                        let elems_25, elems_23, elems_24;
                        return createElement("tr", createObj(singleton((elems_25 = [createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.VmiHub,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.ToOdmCode,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.FromOdmCode,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.AwsPartNumber,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.SupplierSku,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.SupplierCode,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.ExpectedQty,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.UnitPrice,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.EmailAddress,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.Reference1,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.Reference2,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.Reference3,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.CreatedBy,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.UploadedOnFormatted,
                        }), createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_23 = [createElement("span", {
                            className: join(" ", ["tag", "is-flex", "is-rounded", formatOrderStatusAsClass(item_1.OrderStatus)]),
                            children: item_1.OrderStatus,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))), createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_24 = [createElement("span", {
                            className: join(" ", [formatStatusDescriptionAsClass(item_1.OrderStatus, item_1.StatusDescription)]),
                            children: item_1.StatusDescription,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))]))));
                    }, pagedData_1.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])]))));
                }
            }
            default:
                return singleton_1(defaultOf());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])));
}


import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { PagedDataResponse$1, MaterialAdministrationReportResponse, TransactionId_mapToJson, MaterialStatusChangedResponse_$reflection, ApiMessageResponse_$reflection, PagedDataResponse$1_$reflection, MaterialAdministrationReportResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { Deferred_map, Cmd_resetForm, AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { union_type, record_type, unit_type, string_type, int32_type, option_type, class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { Default_pagedData, DataSourceItem_$reflection } from "../Models.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { withUrl, signalr as signalr_1, configureLogging, build, register } from "../SignalR.fs.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_textsConfirmCancel, Alert_questionWithConfirmOrCancel, Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Int32_fromString } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { cancelOngoingUpload, continueOngoingUpload, upload as upload_1, downloadTemplate, search as search_1 } from "../API/MaterialAPI.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { FileType } from "../../FootPrint.Portal.DTOs/CustomTypes.fs.js";
import { map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { map as map_1, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { formatStatusDescriptionAsClass, formatOrderStatusAsClass } from "../Domain/Formatter.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.7.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(PagedDataDeferred, CurrentExpandedItemId, Page, TransactionId, SelectedMaterialStatus, UploadFile, DownloadFileDeferred, UploadFileDeferred, ConfirmUploadDeferred, CancelUploadDeferred, SignalRHub) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.CurrentExpandedItemId = CurrentExpandedItemId;
        this.Page = (Page | 0);
        this.TransactionId = TransactionId;
        this.SelectedMaterialStatus = SelectedMaterialStatus;
        this.UploadFile = UploadFile;
        this.DownloadFileDeferred = DownloadFileDeferred;
        this.UploadFileDeferred = UploadFileDeferred;
        this.ConfirmUploadDeferred = ConfirmUploadDeferred;
        this.CancelUploadDeferred = CancelUploadDeferred;
        this.SignalRHub = SignalRHub;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.MaterialsUpload.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(MaterialAdministrationReportResponse_$reflection()))], ["CurrentExpandedItemId", option_type(class_type("System.Guid"))], ["Page", int32_type], ["TransactionId", string_type], ["SelectedMaterialStatus", option_type(DataSourceItem_$reflection())], ["UploadFile", option_type(class_type("Browser.Types.File", void 0))], ["DownloadFileDeferred", Deferred$1_$reflection(unit_type)], ["UploadFileDeferred", Deferred$1_$reflection(unit_type)], ["ConfirmUploadDeferred", Deferred$1_$reflection(unit_type)], ["CancelUploadDeferred", Deferred$1_$reflection(unit_type)], ["SignalRHub", class_type("SignalR.IHubConnection")]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SearchAsync", "DownloadFileAsync", "UploadFileAsync", "SetNextPage", "SetPreviousPage", "SetUploadFile", "SetConfirmUpload", "ConfirmUploadAsync", "SetCancelUpload", "CancelUploadAsync", "MaterialStatusChanged"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.MaterialsUpload.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(MaterialAdministrationReportResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(MaterialAdministrationReportResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [], [], [["Item", class_type("Browser.Types.File", void 0)]], [["Item", string_type]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", string_type]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", MaterialStatusChangedResponse_$reflection()]]]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

export function init() {
    let signalR;
    const patternInput = register("MaterialStatusChanged", (Item) => (new Msg(10, [Item])), build(configureLogging(1, (signalR = (new signalr_1.HubConnectionBuilder()), withUrl(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api`, signalR)))));
    return [new State(new Deferred$1(0, []), void 0, 1, "", void 0, void 0, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), patternInput[0]), Cmd_batch(ofArray([patternInput[1], singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    })]))];
}

export function update(msg, state) {
    let input_7, input_9, input_13, errors_2;
    switch (msg.tag) {
        case 3:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page + 1, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), singleton((dispatch) => {
                    dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 4:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page - 1, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 5:
            return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, msg.fields[0], state.DownloadFileDeferred, state.UploadFileDeferred, state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), singleton((dispatch_2) => {
                dispatch_2(new Msg(2, [new AsyncMsg$1(0, [])]));
            })];
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_5 = msg.fields[0].fields[0];
                    command_1 = ((input_5.tag === 1) ? Alert_error(Alert_title("Search Materials", Alert_texts(ofArray(input_5.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_7 = msg.fields[0].fields[0], (input_7.tag === 1) ? Default_pagedData() : input_7.fields[0])]), state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), command_1];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        let materialId;
                        let input_3;
                        const input_1 = state.SelectedMaterialStatus;
                        input_3 = ((input_1 == null) ? void 0 : input_1.Id);
                        materialId = ((input_3 == null) ? void 0 : Int32_fromString(input_3));
                        return [new State(new Deferred$1(1, []), state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((x_2) => {
                            Cmd_OfAsync_start(x_2);
                        }, () => search_1(void 0, state.Page), void 0, (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, new Deferred$1(2, [void 0]), state.UploadFileDeferred, state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), (input_9 = msg.fields[0].fields[0], (input_9.tag === 1) ? Alert_error(Alert_title("Download Template", Alert_texts(ofArray(input_9.fields[0].Messages)))) : Cmd_none())];
                default:
                    if (equals(state.DownloadFileDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, new Deferred$1(1, []), state.UploadFileDeferred, state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((x_6) => {
                            Cmd_OfAsync_start(x_6);
                        }, downloadTemplate, void 0, (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
            }
        case 2:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    const uploaded = !(msg.fields[0].fields[0].tag === 1);
                    return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, uploaded ? 1 : state.Page, state.TransactionId, uploaded ? void 0 : state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, new Deferred$1(2, [void 0]), state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), Cmd_batch(ofArray([(input_13 = msg.fields[0].fields[0], (input_13.tag === 1) ? ((errors_2 = input_13.fields[0], !(errors_2.Messages.length === 0) ? Alert_error(Alert_title("Search Materials", Alert_texts(ofArray(errors_2.Messages)))) : Alert_questionWithConfirmOrCancel(new Msg(6, [errors_2.TransactionId]), new Msg(8, [errors_2.TransactionId]), Alert_title("Require Confirmation", Alert_textsConfirmCancel(ofArray(errors_2.RequireConfirmation)))))) : singleton((dispatch_3) => {
                        dispatch_3(new Msg(0, [new AsyncMsg$1(0, [])]));
                    })), singleton((dispatch_4) => {
                        dispatch_4(new Msg(0, [new AsyncMsg$1(0, [])]));
                    }), Cmd_resetForm("upload-form")]))];
                }
                default:
                    if (equals(state.UploadFileDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        const matchValue = state.UploadFile;
                        if (matchValue != null) {
                            const file_1 = matchValue;
                            return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, new Deferred$1(1, []), state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((computation) => {
                                startImmediate(computation);
                            }, () => upload_1(new FileType(4, []), file_1), void 0, (arg_4) => (new Msg(2, [new AsyncMsg$1(2, [arg_4])])))];
                        }
                        else {
                            return [state, Cmd_none()];
                        }
                    }
            }
        case 6:
            return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page, msg.fields[0], state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), singleton((dispatch_5) => {
                dispatch_5(new Msg(7, [new AsyncMsg$1(0, [])]));
            })];
        case 7:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, new Deferred$1(2, [void 0]), state.CancelUploadDeferred, state.SignalRHub), Cmd_none()];
                default:
                    if (equals(state.ConfirmUploadDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, new Deferred$1(1, []), state.CancelUploadDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, () => continueOngoingUpload(TransactionId_mapToJson(state.TransactionId)), void 0, (arg_6) => (new Msg(7, [new AsyncMsg$1(2, [arg_6])])))];
                    }
            }
        case 8:
            return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page, msg.fields[0], state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), singleton((dispatch_6) => {
                dispatch_6(new Msg(9, [new AsyncMsg$1(0, [])]));
            })];
        case 9:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.ConfirmUploadDeferred, new Deferred$1(2, [void 0]), state.SignalRHub), singleton((dispatch_7) => {
                        dispatch_7(new Msg(0, [new AsyncMsg$1(0, [])]));
                    })];
                default:
                    if (equals(state.CancelUploadDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.ConfirmUploadDeferred, new Deferred$1(1, []), state.SignalRHub), Cmd_OfAsyncWith_perform((computation_2) => {
                            startImmediate(computation_2);
                        }, () => cancelOngoingUpload(TransactionId_mapToJson(state.TransactionId)), void 0, (arg_8) => (new Msg(9, [new AsyncMsg$1(2, [arg_8])])))];
                    }
            }
        default:
            return [new State(Deferred_map((pagedData) => (new PagedDataResponse$1(pagedData.Page, pagedData.PageSize, pagedData.TotalCount, pagedData.NumberOfPages, map((item) => {
                if (item.LookupCode === msg.fields[0].LookupCode) {
                    return new MaterialAdministrationReportResponse(msg.fields[0].MaterialId, item.Name, item.Description, item.LookupCode, msg.fields[0].OrderStatusDescription, msg.fields[0].OrderStatusName, item.ErrorMessage);
                }
                else {
                    return item;
                }
            }, pagedData.Data))), state.PagedDataDeferred), state.CurrentExpandedItemId, state.Page, state.TransactionId, state.SelectedMaterialStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.ConfirmUploadDeferred, state.CancelUploadDeferred, state.SignalRHub), Cmd_none()];
    }
}

export function renderTopSection(state, dispatch) {
    let elems_18, elems_1, elems, matchValue, pagedData, elems_17, elems_7, elems_6, elems_3, elems_2, elems_5, elems_4, elems_11, elems_10, elems_9, elems_8, elems_16, elems_15, elems_14, elems_13, elems_12, input_1, x;
    return createElement("form", createObj(ofArray([["id", "upload-form"], ["className", "box"], (elems_18 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link"]),
        children: "Material Management",
    }), createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-self-center"])], (elems = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: (matchValue = state.PagedDataDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((pagedData = matchValue.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_17 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["buttons", "is-right"])], (elems_6 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : equals(state.PagedDataDeferred, new Deferred$1(1, []))], (elems_3 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_2 = [createElement("span", {
        children: ["navigate_before"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("span", {
        className: "is-sr-only",
        children: "Previous",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]), ["onClick", (_arg) => {
        dispatch(new Msg(4, []));
    }]]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : equals(state.PagedDataDeferred, new Deferred$1(1, []))], (elems_5 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_4 = [createElement("span", {
        children: ["navigate_next"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("span", {
        className: "is-sr-only",
        children: "Next",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]), ["onClick", (_arg_1) => {
        dispatch(new Msg(3, []));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["buttons", "is-right"])], (elems_10 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", equals(state.DownloadFileDeferred, new Deferred$1(1, []))], (elems_9 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "mr-3"])], (elems_8 = [createElement("i", {
        children: ["file_download"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("span", {
        children: [equals(state.DownloadFileDeferred, new Deferred$1(1, [])) ? "Downloading..." : "Download Template"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))]), ["onClick", (_arg_2) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["file", "has-name", "is-small", "is-fullwidth"])], (elems_15 = [createElement("label", createObj(ofArray([["className", "file-label"], (elems_14 = [createElement("input", {
        className: join(" ", toList(delay(() => append(singleton_1("file-input"), delay(() => (equals(state.UploadFileDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty())))))),
        type: "file",
        name: "OrderFileUpload",
        disabled: equals(state.UploadFileDeferred, new Deferred$1(1, [])),
        onChange: (ev) => {
            const files = ev.target.files;
            if (!(files == null) && (files.length > 0)) {
                dispatch(new Msg(5, [files.item(0)]));
            }
        },
    }), createElement("span", createObj(ofArray([["className", "file-cta"], (elems_13 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "mr-3"])], (elems_12 = [createElement("i", {
        children: ["file_upload"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("span", {
        className: "file-label",
        children: equals(state.UploadFileDeferred, new Deferred$1(1, [])) ? "Uploading..." : "Upload",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("span", {
        className: "file-name",
        children: defaultArg((input_1 = state.UploadFile, (input_1 == null) ? void 0 : ((x = input_1, x.name))), ""),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])));
}

export function render(state, dispatch) {
    let elems_9, elems_8;
    return createElement("div", createObj(ofArray([["className", join(" ", ["container", "is-fluid"])], (elems_9 = [renderTopSection(state, dispatch), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_8 = toList(delay(() => {
        let elems_7, elems_6, elems_1, elems, elems_5;
        const matchValue = state.PagedDataDeferred;
        switch (matchValue.tag) {
            case 1:
                return singleton_1(render_1());
            case 2: {
                const pagedData = matchValue.fields[0];
                if (pagedData.TotalCount === 0) {
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "Nothing to see here...",
                    }));
                }
                else {
                    const thClasses = ofArray(["px-3", "py-2", "has-text-link"]);
                    const tdClasses = ofArray(["px-3", "py-2"]);
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_7 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_6 = [createElement("thead", createObj(singleton((elems_1 = [createElement("tr", createObj(singleton((elems = [createElement("th", {
                        className: join(" ", thClasses),
                        children: "Lookup Code",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Name",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Description",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Order Status",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Error Description",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])))), createElement("tbody", createObj(singleton((elems_5 = toList(delay(() => map_1((item) => {
                        let elems_4, elems_2, elems_3;
                        return createElement("tr", createObj(singleton((elems_4 = [createElement("td", {
                            className: join(" ", tdClasses),
                            children: item.LookupCode,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item.Name,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item.Description,
                        }), createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_2 = [createElement("span", {
                            className: join(" ", ["tag", "is-flex", "is-rounded", formatOrderStatusAsClass(item.StatusCode)]),
                            children: item.StatusCode,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_3 = [createElement("span", {
                            className: join(" ", ["is-flex", "is-rounded", formatStatusDescriptionAsClass(item.StatusCode, item.OrderStatusDescription)]),
                            children: item.OrderStatusDescription,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))]))));
                    }, pagedData.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))));
                }
            }
            default:
                return singleton_1(defaultOf());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])));
}

